
import { alias47916751af154eb5b47cd5de9d34633d } from '@/customFolder/customVueAlias.js';
// 多语种动态日志-- 目前只处理分销商系统涉及的日志
import {setConfigCharacters} from "@/utils/common";

export const dynamicLog = (obj, contentType, system) => {
  // 动态类型值
  let typeObj = {
    LF: alias47916751af154eb5b47cd5de9d34633d.t('key1001607'),
    FT: alias47916751af154eb5b47cd5de9d34633d.t('key1001608')
  }

  // 处理地址的动态日志数据问题
  function handleAddress(obj) {
    let newList = [];
    let addressLoglist = [
      {
        paramsKey: ['oldBuyerName', 'newBuyerName'],
        value: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001420')} [ ${obj.oldBuyerName} ] ${alias47916751af154eb5b47cd5de9d34633d.t('key1000539')} [ ${obj.newBuyerName} ]`
      },
      {
        paramsKey: ['oldBuyerEmail', 'newBuyerEmail'],
        value: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001421')} [ ${obj.oldBuyerEmail} ] ${alias47916751af154eb5b47cd5de9d34633d.t('key1000539')} [ ${obj.newBuyerEmail} ]`
      },
      {
        paramsKey: ['oldBuyerPhone', 'newBuyerPhone'],
        value: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001422')} [ ${obj.oldBuyerPhone} ] ${alias47916751af154eb5b47cd5de9d34633d.t('key1000539')} [ ${obj.newBuyerPhone} ]`
      },
      {
        paramsKey: ['oldBuyerAccountId', 'newBuyerAccountId'],
        value: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001423')} [ ${obj.oldBuyerAccountId} ] ${alias47916751af154eb5b47cd5de9d34633d.t('key1000539')} [ ${obj.newBuyerAccountId} ]`
      },
      {
        paramsKey: ['oldBuyerCountryCode', 'newBuyerCountryCode'],
        value: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001424')} [ ${obj.oldBuyerCountryCode} ] ${alias47916751af154eb5b47cd5de9d34633d.t('key1000539')} [ ${obj.newBuyerCountryCode} ]`
      },
      {
        paramsKey: ['oldBuyerMobile', 'newBuyerMobile'],
        value: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001425')} [ ${obj.oldBuyerMobile} ] ${alias47916751af154eb5b47cd5de9d34633d.t('key1000539')} [ ${obj.newBuyerMobile} ]`
      },
      {
        paramsKey: ['oldBuyerAddress1', 'newBuyerAddress1'],
        value: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001426')} [ ${obj.oldBuyerAddress1} ] ${alias47916751af154eb5b47cd5de9d34633d.t('key1000539')} [ ${obj.newBuyerAddress1} ]`
      },
      {
        paramsKey: ['oldBuyerAddress2', 'newBuyerAddress2'],
        value: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001427')} [ ${obj.oldBuyerAddress2} ] ${alias47916751af154eb5b47cd5de9d34633d.t('key1000539')} [ ${obj.newBuyerAddress2} ]`
      },
      {
        paramsKey: ['oldBuyerCity', 'newBuyerCity'],
        value: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001428')}  [ ${obj.oldBuyerCity} ] ${alias47916751af154eb5b47cd5de9d34633d.t('key1000539')} [ ${obj.newBuyerCity} ]`
      },
      {
        paramsKey: ['oldBuyerState', 'newBuyerState'],
        value: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001429')} [ ${obj.oldBuyerState} ] ${alias47916751af154eb5b47cd5de9d34633d.t('key1000539')} [ ${obj.newBuyerState} ]`
      },
      {
        paramsKey: ['oldBuyerPostalCode', 'newBuyerPostalCode'],
        value: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001430')} [ ${obj.oldBuyerPostalCode} ] ${alias47916751af154eb5b47cd5de9d34633d.t('key1000539')} [ ${obj.newBuyerPostalCode} ]`
      },
      {
        paramsKey: ['oldBuyerPassportCode', 'newBuyerPassportCode'],
        value: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001431')} [ ${obj.oldBuyerPassportCode} ] ${alias47916751af154eb5b47cd5de9d34633d.t('key1000539')} [ ${obj.newBuyerPassportCode} ]`
      },
    ];
    if (Object.keys(obj).length > 0) {
      for (let key in obj) {
        addressLoglist.map((item) => {
          if (item.paramsKey.includes(key)) {
            newList.push(item.value)
          }
        })
      }
    }
    newList.unshift(`${setConfigCharacters}${alias47916751af154eb5b47cd5de9d34633d.t('key1001432')} ${obj.packageCode} ${alias47916751af154eb5b47cd5de9d34633d.t('key1001433')}`);
    let text = [...new Set(newList)].join(';');
    return text;
  }

  // 处理有些动态日志不需要展示在分销商但是需要展示运营
  function handleDynamicLogs(obj, system) {
    let text = '';
    if (contentType === 40) {
      if (system === 'distributor') {
        text = `${alias47916751af154eb5b47cd5de9d34633d.t('key1001434')}${obj.currency} ${obj.amount}`;
      } else if (system === 'yms') {
        text = `${alias47916751af154eb5b47cd5de9d34633d.t('key1001435')}${obj.currency} ${obj.amount}`;
      }
    }
    if (contentType === 48) {
      if (system === 'distributor') {
        text = '';
      } else if (system === 'yms') {
        text = `${alias47916751af154eb5b47cd5de9d34633d.t('key1001436')}${obj.totalPriceCurrency}  ${obj.totalPrice}
         ${alias47916751af154eb5b47cd5de9d34633d.t('key1001437')}${obj.totalPriceCurrency}  ${obj.distributorStockCost}
         ${alias47916751af154eb5b47cd5de9d34633d.t('key1001438')}${obj.supplierTotalPriceCurrency}  ${obj.supplierTotalPrice}`
      }
    }
    if (contentType === 52) {
      if (system === 'distributor') {
        text = '';
      } else if (system === 'yms') {
        text = `${obj.subPackageCode} ${alias47916751af154eb5b47cd5de9d34633d.t('key1001439')}`;
      }
    }
    return text;
  }

  /*;是用于页面显示的时候换行展示*/
  let logsObj = {
    1: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001440')}${setConfigCharacters}${alias47916751af154eb5b47cd5de9d34633d.t('key1001441')}`,
    2: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001442')}`,
    3: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001443')}`,
    4: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001444')}`,
    5: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001445')}`,
    6: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001446')}`,
    7: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001447')}${setConfigCharacters}${alias47916751af154eb5b47cd5de9d34633d.t('key1001448')} [ ${obj.packageCode} ]`,
    8: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001449')} ${setConfigCharacters}${alias47916751af154eb5b47cd5de9d34633d.t('key1001448')} [ ${obj.packageCode} ${alias47916751af154eb5b47cd5de9d34633d.t('key1001450')} ${obj.ruleName} ${alias47916751af154eb5b47cd5de9d34633d.t('key1001451')} ${obj.ymsLogisName} ${alias47916751af154eb5b47cd5de9d34633d.t('key1001452')} ${typeObj[obj.dynamicMessage]}`,
    9: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001453')}${obj.platformItemSku}${alias47916751af154eb5b47cd5de9d34633d.t('key1001454')}${setConfigCharacters}SKU：${obj.ymsSku}`,
    10: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001455')}${obj.platformItemSku}${alias47916751af154eb5b47cd5de9d34633d.t('key1001456')}${setConfigCharacters}SKU`,
    11: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001457')}${obj.platformItemSku}${alias47916751af154eb5b47cd5de9d34633d.t('key1001454')}${setConfigCharacters}SKU：${obj.ymsSku}`,
    12: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001458')}${obj.platformItemSku}${alias47916751af154eb5b47cd5de9d34633d.t('key1001454')}${setConfigCharacters}${alias47916751af154eb5b47cd5de9d34633d.t('key1001459')}`,
    13: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001458')}${obj.platformItemSku}${alias47916751af154eb5b47cd5de9d34633d.t('key1001456')}${setConfigCharacters}SKU`,
    14: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001449')} ${setConfigCharacters}${alias47916751af154eb5b47cd5de9d34633d.t('key1001448')} [ ${obj.packageCode} ]`,
    15: `${setConfigCharacters}${alias47916751af154eb5b47cd5de9d34633d.t('key1001448')} [ ${obj.packageCode} ] ${alias47916751af154eb5b47cd5de9d34633d.t('key1001460')}`,
    16: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001461')}${obj.carrierShippingMethodName} ${alias47916751af154eb5b47cd5de9d34633d.t('key1001462')}${obj.trackingNumber} ${alias47916751af154eb5b47cd5de9d34633d.t('key1001463')}${obj.thirdPartyNo}`,
    17: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001464')}${obj.message}`,
    18: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001465')}${obj.trackingNumber}`,
    19: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001466')}${obj.message}`,
    20: `${setConfigCharacters}${alias47916751af154eb5b47cd5de9d34633d.t('key1001448')} [ ${obj.packageCode} ] ${alias47916751af154eb5b47cd5de9d34633d.t('key1001467')}`,
    21: `${setConfigCharacters}${alias47916751af154eb5b47cd5de9d34633d.t('key1001448')} [ ${obj.packageCode} ] ${alias47916751af154eb5b47cd5de9d34633d.t('key1001468')}`,
    22: `${alias47916751af154eb5b47cd5de9d34633d.t('key1000965')} ${obj.packageCode} ${alias47916751af154eb5b47cd5de9d34633d.t('key1001469')} ${alias47916751af154eb5b47cd5de9d34633d.t('key1001470')})`,
    23: `${setConfigCharacters}${alias47916751af154eb5b47cd5de9d34633d.t('key1001448')} [ ${obj.packageCode} ] ${alias47916751af154eb5b47cd5de9d34633d.t('key1001467')} ${obj.message}`,
    24: `${setConfigCharacters}${alias47916751af154eb5b47cd5de9d34633d.t('key1001432')} ${obj.packageCode} ] ${alias47916751af154eb5b47cd5de9d34633d.t('key1001471')}`,
    25: `${setConfigCharacters}${alias47916751af154eb5b47cd5de9d34633d.t('key1001432')} ${obj.packageCode} ${alias47916751af154eb5b47cd5de9d34633d.t('key1001472')} ${alias47916751af154eb5b47cd5de9d34633d.t('key1001473')} [ ${obj.oldCarrierContent} ] ${alias47916751af154eb5b47cd5de9d34633d.t('key1001474')} [ ${obj.newCarrierContent} ]`,
    26: `${setConfigCharacters}${alias47916751af154eb5b47cd5de9d34633d.t('key1001432')} ${obj.packageCode} ${alias47916751af154eb5b47cd5de9d34633d.t('key1001475')}`,
    27: handleAddress(obj),
    28: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001476')}`,
    29: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001477')}`,
    30: `${alias47916751af154eb5b47cd5de9d34633d.t('key1000400')}`,
    31: `${alias47916751af154eb5b47cd5de9d34633d.t('key1000399')}`,
    32: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001478')}`,
    33: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001479')}`,
    34: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001480')}${obj.iossName}${alias47916751af154eb5b47cd5de9d34633d.t('key1001481')}${obj.iossCode}`,
    35: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001482')}${obj.iossName}${alias47916751af154eb5b47cd5de9d34633d.t('key1001481')}${obj.iossCode}`,
    36: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001483')}${obj.iossName}${alias47916751af154eb5b47cd5de9d34633d.t('key1001484')}${obj.iossCode}${alias47916751af154eb5b47cd5de9d34633d.t('key1001485')}${obj.platformIds}`,
    37: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001483')}${obj.iossName}${alias47916751af154eb5b47cd5de9d34633d.t('key1001484')}${obj.iossCode}${alias47916751af154eb5b47cd5de9d34633d.t('key1001486')}${obj.shopIds}`,
    38: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001487')}${obj.iossName}${alias47916751af154eb5b47cd5de9d34633d.t('key1001484')}${obj.iossCode}${alias47916751af154eb5b47cd5de9d34633d.t('key1001485')}${obj.platformIds}`,
    39: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001487')}${obj.iossName}${alias47916751af154eb5b47cd5de9d34633d.t('key1001484')}${obj.iossCode}${alias47916751af154eb5b47cd5de9d34633d.t('key1001486')}${obj.shopIds}`,
    40: handleDynamicLogs(obj, system),
    41: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001488')}${obj.currency} ${obj.amount}`,
    42: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001489')}${obj.currency} ${obj.amount}`,
    43: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001490')}`,
    44: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001491')}`,
    45: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001492')}`,
    46: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001493')} ${obj.amount}${alias47916751af154eb5b47cd5de9d34633d.t('key1001494')}${obj.currency}`,
    47: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001495')}`,
    48: handleDynamicLogs(obj, system),
    49: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001496')}`,
    50: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001497')}${obj.estimateArrivalOfGoodsTime}${alias47916751af154eb5b47cd5de9d34633d.t('key1001498')}${obj.ymsSkuContent}`,
    51: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001499')}${obj.ymsSkuContent}`,
    52: handleDynamicLogs(obj, system),
    53: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001500')}`,
    54: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001501')}`,
    55: `${alias47916751af154eb5b47cd5de9d34633d.t('key1000399')}${obj.cnValue}${alias47916751af154eb5b47cd5de9d34633d.t('key1000218')}`,
    56: `${alias47916751af154eb5b47cd5de9d34633d.t('key1000400')}${obj.cnValue}${alias47916751af154eb5b47cd5de9d34633d.t('key1000218')}`,
    57: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001061')}${obj.cnValue}${alias47916751af154eb5b47cd5de9d34633d.t('key1000218')}`,
    58: `${alias47916751af154eb5b47cd5de9d34633d.t('key1000227')}${obj.content}`,
    59: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001502')}`,
    60: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001503')}`,
    61: `${obj.subPackageCode}${alias47916751af154eb5b47cd5de9d34633d.t('key1001439')}`,
    62: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001504')}`,
    63: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001505')}`,
    64: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001506')}${obj.carrierShippingMethodName}${alias47916751af154eb5b47cd5de9d34633d.t('key1001507')}${obj.trackingNumber}${alias47916751af154eb5b47cd5de9d34633d.t('key1001508')}${obj.thirdPartyNo}`,
    65: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001509')}`,
    66: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001510')}`,
    67: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001511')}`,
    68: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001512')}`,
    69: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001513')}`,
    70: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001514')}`,
    71: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001515')}${obj.rate}`,
    72: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001516')}${obj.rate}`,
    73: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001517')}`,
    74: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001518')}`,
    75: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001519')}`,
    76: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001520')}${obj.ymsPickingOrderCode}${alias47916751af154eb5b47cd5de9d34633d.t('key1001521')}`,
    77: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001284')}`,
    78: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001522')}`,
    79: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001523')}`,
    80: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001524')}${obj.code}】`,
    81: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001525')}`,
    82: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001526')}`,
    83: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001527')}`,
    84: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001528')}`,
    85: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001529')}`,
    86: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001530')}`,
    87: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001531')}`,
    88: `${alias47916751af154eb5b47cd5de9d34633d.t('key1000706')}`,
    89: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001532')}${obj.describe}`,
    90: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001533')}  ${alias47916751af154eb5b47cd5de9d34633d.t('key1001534')} ${alias47916751af154eb5b47cd5de9d34633d.t('key1001535')}${obj.describe}`,
    91: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001536')} ${alias47916751af154eb5b47cd5de9d34633d.t('key1001534')} ${alias47916751af154eb5b47cd5de9d34633d.t('key1000706')}`,
    92: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001533')} ${alias47916751af154eb5b47cd5de9d34633d.t('key1001534')} ${alias47916751af154eb5b47cd5de9d34633d.t('key1000706')}`,
    93: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001536')} ${alias47916751af154eb5b47cd5de9d34633d.t('key1001534')} ${alias47916751af154eb5b47cd5de9d34633d.t('key1001537')} ${alias47916751af154eb5b47cd5de9d34633d.t('key1001532')}${obj.describe}`,
    94: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001538')} ${alias47916751af154eb5b47cd5de9d34633d.t('key1001539')}${obj.subsidyAmount}${obj.subsidyAmountCurrency}${alias47916751af154eb5b47cd5de9d34633d.t('key1001540')}${obj.modifySubsidyMoney}${obj.subsidyAmountCurrency}${alias47916751af154eb5b47cd5de9d34633d.t('key1001541')}${obj.describe}`,
    95: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001531')}`,
    96: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001542')}`,
    97: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001543')}`,
    98: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001544')}`,
    99: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001545')}`,
    100: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001546')}`,
    101: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001547')}`,
    102: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001548')}${obj.auditRemarks}`,
    103: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001549')}`,
    104: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001550')}`,
    105: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001551')}`,
    106: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001552')}${obj.carrierName}：${obj.carrierShippingMethodName}`,
    107: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001553')}`,
    108: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001282')}`,
    109: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001554')}${obj.packageCode}${alias47916751af154eb5b47cd5de9d34633d.t('key1001555')}${obj.warehouseName}`,
    110: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001557')}${obj.invalidReason || alias47916751af154eb5b47cd5de9d34633d.t('key1001556')}`,
    111: `${setConfigCharacters}${alias47916751af154eb5b47cd5de9d34633d.t('key1001558')}${obj.oldName}${alias47916751af154eb5b47cd5de9d34633d.t('key1001559')}${obj.newName}]`,
    112: `${alias47916751af154eb5b47cd5de9d34633d.t('key1000965')}${obj.packageCode}${alias47916751af154eb5b47cd5de9d34633d.t('key1001449')}${obj.carrierName}_${obj.carrierShippingMethodName}${alias47916751af154eb5b47cd5de9d34633d.t('key1001560')}(${obj.ruleName})`,
    113: `${alias47916751af154eb5b47cd5de9d34633d.t('key1000965')}${obj.packageCode}${alias47916751af154eb5b47cd5de9d34633d.t('key1001449')}${obj.carrierName}_${obj.carrierShippingMethodName}${alias47916751af154eb5b47cd5de9d34633d.t('key1001561')}`,
    114: `${alias47916751af154eb5b47cd5de9d34633d.t('key1000965')}${obj.packageCode}${alias47916751af154eb5b47cd5de9d34633d.t('key1001449')}${obj.carrierName}_${obj.carrierShippingMethodName}${alias47916751af154eb5b47cd5de9d34633d.t('key1001562')}`,
    115: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001563')}`,
    116: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001564')}`,
    117: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001565')}`,
    118: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001566')}`,
    119: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001567')} ${alias47916751af154eb5b47cd5de9d34633d.t('key1001568')}${obj.trackingNumber})`,
    120: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001564')} ${alias47916751af154eb5b47cd5de9d34633d.t('key1001569')}${obj.carrierErrorMsg})`,
    121: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001570')}`,
    122: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001571')}`,
    123: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001572')}`,
    124: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001573')}${obj.businessId}】`,
    125: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001574')}`,
    126: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001575')}`,
    127: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001576')}`,
    128: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001577')}`,
    129: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001578')}`,
    130: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001579')}`,
    131: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001580')}`,
    132: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001581')}`,
    133: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001582')}`,
    134: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001583')}${obj.invalidReason}`,
    135: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001584')}`,
    136: `${alias47916751af154eb5b47cd5de9d34633d.t('key1000518')}`,
    137: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001585')}`,
    138: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001586')}${obj.renewalPrice}${alias47916751af154eb5b47cd5de9d34633d.t('key1001587')}${obj.renewalMonth} ${alias47916751af154eb5b47cd5de9d34633d.t('key1001588')}`,
    139: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001589')}`,
    140: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001590')}${obj.auditRemarks}`,
    141: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001591')}${obj.content}`,
    142: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001592')}`,
    143: `${setConfigCharacters}${alias47916751af154eb5b47cd5de9d34633d.t('key1001593')}${obj.packageTagName}`,
    144: `${setConfigCharacters}${alias47916751af154eb5b47cd5de9d34633d.t('key1001594')}${obj.packageTagName}`,
    145: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001595')}`,
    146: `${setConfigCharacters}${alias47916751af154eb5b47cd5de9d34633d.t('key1001596')}${obj.content}`,
    147: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001597')}`,
    148: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001598')}`,
    149: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001599')}`,
    150: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001600')}`,
    151: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001601')}${obj.ymsSpu}`,
    152: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001602')}${obj.ymsSku}`,
    153: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001458')}${obj.platformItemSku}${alias47916751af154eb5b47cd5de9d34633d.t('key1001603')}`,
    159: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001604')}`,
    162: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001605')} (${obj.platformWarehouseName})`,
    163: `${alias47916751af154eb5b47cd5de9d34633d.t('key1001606')}`,
  };
  return logsObj[contentType];

}
